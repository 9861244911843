<script>
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";
import { mapGetters, mapMutations } from "vuex";
import api from "@/services/api";

import BaseSearchInput from "@/components/Reusable/BaseSearchInput";
import BaseEditIcon from "@/components/icons/BaseEditIcon";
import PlansPermission from "@/permission/plans";
import BaseButton from "@/components/Reusable/BaseButton";
import BaseDeleteIcon from "@/components/icons/BaseDeleteIcon";
import BaseLoadingContent from "@/components/BaseLoadingContent";
import AppHeader from "@/components/Header/AppHeader";
import { XButton } from "@/components/ui-components/button";
import BaseArrowLeftIcon from "@/components/icons/BaseArrowLeftIcon";
import BaseArrowRightIcon from "@/components/icons/BaseArrowRightIcon";
import { XFormSelect } from "@/components/ui-components/form-select";
import { v3ServiceApi } from "@/services/v3/v3.service";

import SearchBarContent from "./SearchBarContent";
import {
  formatDateWithDot,
  formatToPrice,
  phonePrettier,
  sortObjectValues,
} from "@/util/reusable";

export default {
  name: "TypeParkingList",
  components: {
    XFormSelect,
    BaseArrowRightIcon,
    BaseArrowLeftIcon,
    BaseLoadingContent,

    BaseSearchInput,
    BaseButton,
    BaseEditIcon,
    AppHeader,
    XButton,
    SearchBarContent,
  },
  data() {
    return {
      page: +this.$route.query.page || 1,
      limit: +this.$route.query.limit || 10,
      totalPage: 0,
      showByValue: +this.$route.query.limit || 10,
      showByOptions: [5, 10, 20, 30].map((el) => ({ value: el, text: el })),
      logs: [],

      showLoading: false,

      loading: false,
    };
  },
  computed: {
    fields() {
      return [
        { key: "apartment", label: this.$t("apartment") },
        {
          key: "name",
          label: this.$t("fio"),
        },
        {
          key: "object_name",
          label: this.$t("apartments.filter.object"),
        },
        {
          key: "count",
          label: this.$t("holdCount"),
          thStyle: { width: "400px" },
        },
      ];
    },
    ...mapGetters(["getLoading", "getPermission"]),
    query() {
      return { ...this.$route.query };
    },
  },
  watch: {
    "$route.query": {
      handler(value) {
        if (value) {
          this.fetchLogs();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.$router.push({
      query: { ...this.query, page: this.page, limit: this.limit },
    });
    this.fetchLogs();
    Fancybox.bind("[data-fancybox]");
  },
  methods: {
    formatDate: (v) => formatDateWithDot(v),
    pushRouter(query) {
      const sortQuery = sortObjectValues(query);
      this.$router.push({ query: {} });
      this.$router.push({ query: sortQuery });
    },
    changeFetchLimit() {
      const query = {
        ...this.query,
        page: this.query.page || 1,
      };
      const limit = this.showByValue;
      this.$router.push({ query: { ...query, limit } });
    },
    changeCurrentPage(page) {
      const currentPage = this.query.page;
      if (+page === +currentPage) return;
      this.page = page;
      const query = {
        ...this.query,
        page: this.query.page || 1,
      };
      const limit = this.query.limit || 10;
      this.$router.replace({ query: { ...query, page, limit } });
    },
    ...mapMutations(["updateLoading"]),
    async fetchLogs() {
      this.updateLoading(true, { root: true });
      this.showLoading = true;
      try {
        const q = { ...this.query };
        if (q.user_id) {
          if (Array.isArray(q.user_id)) {
            q.user_id = q.user_id.map((el) => +el);
          } else {
            q.user_id = +q.user_id;
          }
        }
        const response = await v3ServiceApi.holdLogs.fetch(q);
        this.logs = response.data.data.map((el) => ({
          ...el,
          collapse: false,
        }));
        this.totalPage = response.data.pagination.totalPage;

        this.updateLoading(false, { root: true });
        this.showLoading = false;
      } catch (error) {
        this.updateLoading(false, { root: true });
        this.showLoading = false;
        if (!error.response) {
          this.toasted("Error: Network Error", "error");
        } else {
          this.toasted(error.response.data.message, "error");
        }
      }
    },
    searchQueryFilter(searchQuery) {
      // eslint-disable-next-line no-prototype-builtins
      const hasQueryStatus = this.query.hasOwnProperty("status");
      if (hasQueryStatus) {
        const { status } = this.query;
        this.pushRouter({
          ...searchQuery,
          status,
        });
        return;
      }

      this.pushRouter(searchQuery);
    },
  },
};
</script>

<template>
  <div>
    <app-header>
      <template #header-title>
        {{ $t("holdLogTitle") }}
      </template>
    </app-header>

    <div class="search__content">
      <div class="d-flex x-gap-1">
        <div></div>
        <search-bar-content
          ref="filterModal"
          @replace-router="searchQueryFilter"
          @search-by-filter="searchQueryFilter"
        />
      </div>
    </div>

    <div>
      <div class="mt-4">
        <b-table
          thead-tr-class="row__head__bottom-border"
          tbody-tr-class="row__body__bottom-border"
          class="table__list"
          sticky-header
          show-empty
          borderless
          responsive
          :items="logs"
          :empty-text="$t('no_data')"
          :fields="fields"
          :busy="showLoading"
        >
          <template #empty="scope" class="text-center">
            <div class="d-flex justify-content-center align-items-center">
              {{ scope.emptyText }}
            </div>
          </template>
          <template #table-busy>
            <div class="d-flex justify-content-center w-100">
              <div class="lds-ellipsis">
                <div />
                <div />
                <div />
                <div />
              </div>
            </div>
          </template>

          <template #cell(name)="data">
            {{ data.item.user_name }} {{ data.item.last_name }}
          </template>
          <template #cell(count)="{ item }">
            <div class="wrapper">
              <div
                @click="item.collapse = !item.collapse"
                :style="{
                  borderBottom: item.collapse ? '1px solid black' : '',
                }"
                class="count"
                :class="{ open: item.collapse }"
              >
                {{ item.count }}
              </div>
              <b-collapse v-model="item.collapse">
                <div v-for="entry in item.created_at" :key="entry">
                  {{ formatDate(entry) }} {{ entry.split(" ")[1].slice(0, 5) }}
                </div>
              </b-collapse>
            </div>
          </template>
        </b-table>
        <div v-if="!showLoading && totalPage" class="pagination__vue">
          <vue-paginate
            :page-count="totalPage"
            :value="page"
            :container-class="'container'"
            :page-class="'page-item'"
            :page-link-class="'page-link'"
            :next-class="'page-item'"
            :prev-class="'page-item'"
            :prev-link-class="'page-link'"
            :next-link-class="'page-link'"
            @change-page="changeCurrentPage"
          >
            <template #next-content>
              <span class="d-flex align-items-center justify-content-center">
                <base-arrow-right-icon />
              </span>
            </template>

            <template #prev-content>
              <span class="d-flex align-items-center justify-content-center">
                <base-arrow-left-icon />
              </span>
            </template>
          </vue-paginate>

          <div class="show__by">
            <x-form-select
              v-model="showByValue"
              :label="false"
              :options="showByOptions"
              @change="changeFetchLimit"
            >
              <template #output-prefix>
                <span class="show-by-description">
                  {{ $t("contracts.show_by") }}:
                </span>
              </template>
            </x-form-select>
          </div>
        </div>
      </div>
    </div>

    <base-loading-content :loading="loading" />
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/scss/utils/pagination.scss";
.wrapper {
  padding: 5px 10px;
  border-radius: 10px;
  background-color: #eee;

  & .count {
    position: relative;
    &:after {
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      right: 20px;
      top: 45%;
      transform: translateY(-50%) rotateZ(-45deg);
      border-left: 2px solid black;
      border-bottom: 2px solid black;
      transition: all 0.3s;
    }

    &.open:after {
      top: 55%;
      transform: translateY(-50%) rotateZ(135deg);
    }
  }
}

.label {
  color: #7c3aed;
  margin-right: 3px;
  font-weight: 500;
}

.fal {
  font-weight: 500;
}

.text {
  font-weight: 600;
}

.button {
  height: auto;
  background-color: #7c3aed;
  width: auto;
  padding: 8px;

  ::v-deep span {
    margin-left: 0 !important;
  }
}

.actions {
  display: flex;
  gap: 16px;
}

.search__content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1rem;
}

::v-deep .row__head__bottom-border {
  border-bottom: 2px solid var(--gray-200) !important;
}

::v-deep .row__body__bottom-border:not(:last-child) {
  border-bottom: 2px solid var(--gray-200) !important;
}

::v-deep .table__list {
  min-height: 250px;
  max-height: none;

  table {
    color: var(--gray-600);

    thead tr th {
      font-family: CraftworkSans, serif;
      font-weight: 900;
      font-size: 14px;
      line-height: 14px;
      letter-spacing: 1px;
      color: var(--gray-400) !important;
      padding: 1.125rem 1rem;
      vertical-align: middle;

      //&.b-table-sort-icon-left {
      //display: flex;
      //align-items: center;
      //}
    }

    td {
      font-family: Inter, sans-serif;
      font-size: 1rem;
      line-height: 22px;
      font-weight: 600;
      vertical-align: middle;
    }
  }

  .table.b-table[aria-busy="true"] {
    opacity: 1 !important;
  }
}

::v-deep .table.b-table > thead > tr > [aria-sort="none"],
::v-deep .table.b-table > tfoot > tr > [aria-sort="none"] {
  background-position: right calc(2rem / 2) center !important;
  //background-position: right !important;
  padding-right: 20px;
}

::v-deep .table.b-table > thead > tr > [aria-sort="ascending"],
::v-deep .table.b-table > tfoot > tr > [aria-sort="ascending"] {
  background-position: right calc(2rem / 2) center !important;
  background-size: 20px;
  background-image: url("../../assets/icons/icon-arrow-down.svg") !important;
}

::v-deep .table.b-table > thead > tr > [aria-sort="descending"],
::v-deep .table.b-table > tfoot > tr > [aria-sort="descending"] {
  background-position: right calc(2rem / 2) center !important;
  background-size: 20px;
  background-image: url("../../assets/icons/icon-arrow-up.svg") !important;
}
</style>
